import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';

import { LoginComponent } from '@app/modules/auth/login/login.component';
import { OAuthModule } from 'angular-oauth2-oidc';

import { RedirectEndpointComponent } from './redirect-endpoint/redirect-endpoint.component';
import { SilentRefreshComponent } from './silent-refresh.component';

@NgModule({
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true
      }
    })
  ],
  declarations: [
    RedirectEndpointComponent,
    SilentRefreshComponent,
    LoginComponent
  ],
  exports: [RedirectEndpointComponent, SilentRefreshComponent, LoginComponent]
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule
    };
  }
}
