import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@app/components/home/home.component';

import { AuthGuard } from './modules/auth/guard/auth.guard';
import { LoginComponent } from './modules/auth/login/login.component';
import { RedirectEndpointComponent } from './modules/auth/redirect-endpoint/redirect-endpoint.component';
import { SilentRefreshComponent } from './modules/auth/silent-refresh.component';
import { ROUTES } from './shared/routes/routes.constants';

const routes: Routes = [
  {
    path: ROUTES.HOME,
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  { path: ROUTES.REDIRECT_URI, component: RedirectEndpointComponent },
  { path: ROUTES.LOGIN, component: LoginComponent },
  { path: ROUTES.SILENT_REFRESH, component: SilentRefreshComponent },
  { path: '**', redirectTo: ROUTES.HOME }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
