import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { authCodeFlowConfig } from '@app/modules/auth/auth-code-flow.config';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService
  ) {}

  ngOnInit(): void {
    this.configureAuth();
  }

  private configureAuth() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => this.router.initialNavigation());
  }
}
