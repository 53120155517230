import { Component } from '@angular/core';

import { environment } from '@root/environments/environment';

import { CARDS_LIST } from '../../constants/cards-list/cards-list.constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  portalApps = environment.portalApps as any;

  CARDS = CARDS_LIST.sort((a, b) => a.title.localeCompare(b.title));

  openLink(link: string) {
    window.open(this.portalApps[link]);
  }
}
