<ng-container *transloco="let t">
  <div class="mt-l mb-xl" >
    <h1 class="mb-0">{{ t('home.subtitle') }}</h1>
    <div
      class="d-grid gap-sm cols-3 justify-content-left mt-sm gap "
    >
      <ng-container *ngFor="let card of CARDS">
        <cx-card
        class="clickable "
        headerIcon="{{card.headerIcon}}"
        subtitle="{{ t(card.title) }}"
        description="{{ t(card.description) }}"
        (click)="openLink(card.link)"
        >
        </cx-card>
      </ng-container>
    </div>
  </div>
</ng-container>
