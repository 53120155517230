export const CARDS_LIST = [
  {
    headerIcon: 'fact_check',
    title: 'home.cards.fem.title',
    description: 'home.cards.fem.info',
    link: 'fem'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.eshop.title',
    description: 'home.cards.eshop.info',
    link: 'eshop'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.baroloop.title',
    description: 'home.cards.baroloop.info',
    link: 'baroloop'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.tom.title',
    description: 'home.cards.tom.info',
    link: 'tom'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.sd.title',
    description: 'home.cards.sd.info',
    link: 'sd'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.dfm.title',
    description: 'home.cards.dfm.info',
    link: 'dfm'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.resi.title',
    description: 'home.cards.resi.info',
    link: 'resi'
  },
  {
    headerIcon: 'fact_check',
    title: 'home.cards.bsm.title',
    description: 'home.cards.bsm.info',
    link: 'bsm'
  }
];
