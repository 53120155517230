export const environment = {
  environment: 'DEV',
  enableProdMode: false,
  backendUrl: '',
  postLogoutRedirectUri: 'https://portal.discodev.bbraun.io/',
  portalApps: {
    fem: 'https://fem.dev.bbraun.io',
    eshop: 'https://shop.bbraun.com',
    baroloop: 'https://kind-cliff-0f2015703.1.azurestaticapps.net/#/',
    tom: 'https://tom.bbraun.cloud/',
    pim: 'https://productdev.products.bbraun.com/',
    sd: 'https://sd.iomtuat.bbraun.io/',
    dfm: 'https://portal.iomtuat.bbraun.io/',
    resi: 'https://dev.resi.bbraun.io/',
    bsm: 'https://bsm.bbraun.cloud'
  },
  auth: {
    clientId: '92d21996-dac3-4691-ae59-7bc55230509a',
    logoutUrl:
      'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/oauth2/B2C_1A__SIGNUP_SIGNIN_MFA/v2.0/logout',
    issuer:
      'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/v2.0/',
    scope:
      'openid profile offline_access https://test.account.bbraun.com/auth/check/Relations.User.Read'
  }
};
