import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from '@app/constants/languages.contants';
import { LOCAL_STORAGE } from '@app/constants/local-storage/local-storage.constants';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { CxCardModule } from '@bbraun/cortex/card';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { TRANSLOCO_CONFIG, translocoConfig } from '@ngneat/transloco';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { AuthModule } from './modules/auth/auth.module';
import { AuthGuard } from './modules/auth/guard/auth.guard';

@NgModule({
  declarations: [AppComponent, HomeComponent, HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CxCardModule,
    CxDialogModule,
    CxHeaderModule,
    TranslocoRootModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    AuthModule.forRoot()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang:
          localStorage.getItem(LOCAL_STORAGE.LANGUAGE) ?? DEFAULT_LANGUAGE,
        fallbackLang: DEFAULT_LANGUAGE,
        availableLangs: AVAILABLE_LANGUAGES,
        reRenderOnLangChange: true,
        missingHandler: {
          useFallbackTranslation: true
        }
      })
    }
  ]
})
export class AppModule {}
