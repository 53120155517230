import { KeyValue } from '@angular/common';

export const LANGUAGES: Array<KeyValue<string, string>> = [
  {
    key: 'en',
    value: 'languages.en'
  },
  {
    key: 'de',
    value: 'languages.de'
  }
];

export const AVAILABLE_LANGUAGES: Array<string> = ['en', 'de'];

export const DEFAULT_LANGUAGE = 'en';
