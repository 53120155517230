import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';

import { LOCAL_STORAGE } from '@app/constants/local-storage/local-storage.constants';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-redirect-endpoint',
  templateUrl: './redirect-endpoint.component.html'
})
export class RedirectEndpointComponent implements AfterViewChecked {
  wasNotCalled = true;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {}

  ngAfterViewChecked() {
    if (
      this.oauthService.getIdToken() ||
      this.oauthService.getAccessToken() ||
      this.wasNotCalled
    ) {
      this.router.navigateByUrl('/');
      return;
    }

    const route = localStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) ?? '';

    this.wasNotCalled = false;

    this.router.navigateByUrl(route);
  }
}
