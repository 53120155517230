import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { LOCAL_STORAGE } from '@root/app/constants/local-storage/local-storage.constants';
import { ROUTES } from '@root/app/shared/routes/routes.constants';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService
  ) {}

  canActivate() {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      return true;
    }

    if (!location.pathname.includes(ROUTES.REDIRECT_URI)) {
      localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, location.pathname);
    }

    this.router.navigate(['/' + ROUTES.LOGIN]);

    return false;
  }
}
