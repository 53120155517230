import { Component, OnInit } from '@angular/core';

import { LANGUAGES } from '@app/constants/languages.contants';
import { LOCAL_STORAGE } from '@app/constants/local-storage/local-storage.constants';
import { CxDialogService } from '@bbraun/cortex/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { OAuthService } from 'angular-oauth2-oidc';
import { take } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  LANGUAGES = LANGUAGES;
  currentLang: string;

  constructor(
    private readonly oAuthService: OAuthService,
    private readonly translocoService: TranslocoService,
    private readonly dialogService: CxDialogService
  ) {}

  ngOnInit() {
    this.currentLang = this.translocoService.getActiveLang();
  }

  changeLanguage(lang: string): void {
    this.translocoService
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.translocoService.setActiveLang(lang);
        this.currentLang = this.translocoService.getActiveLang();

        localStorage.setItem(LOCAL_STORAGE.LANGUAGE, this.currentLang);
      });
  }

  openLogOutDialog(): void {
    this.dialogService
      .openConfirmDialog(
        this.translocoService.translate('header.logOutDialog.title'),
        this.translocoService.translate('header.logOutDialog.description'),
        this.translocoService.translate('header.logOutDialog.confirmButton'),
        this.translocoService.translate('header.logOutDialog.cancelButton')
      )
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }

        this.oAuthService.logOut();
      });
  }
}
