<ng-container *transloco="let t">
  <cx-header>
    <cx-header-title>{{ t('home.title') }}</cx-header-title>

    <cx-header-quickbar class="quickbar ml-sm">
      <button
        mat-icon-button
        class="white-icon-header menu-button"
        #userMenuTrigger="matMenuTrigger"
        [matTooltip]="t('header.userDropdownTriggerTooltip')"
        [matMenuTriggerFor]="userMenu"
        [class.menu-button-opened]="userMenuTrigger.menuOpen"
      >
        <mat-icon class="white-medium-icon">
          account_circle
        </mat-icon>
      </button>
      <mat-menu
        class="quickbar-menu user-profile-menu"
        xPosition="before"
        #userMenu="matMenu"
      >
        <button mat-menu-item (click)="openLogOutDialog()">
          <mat-icon>logout</mat-icon>
          <span>{{ t('header.logOutButton') }}</span>
        </button>
      </mat-menu>
      <button
        mat-icon-button
        class="white-icon-header menu-button ml-sm"
        #trigger="matMenuTrigger"
        [matTooltip]="t('header.selectLanguageTriggerTooltip')"
        [matMenuTriggerFor]="langSelect"
        [class.menu-button-opened]="trigger.menuOpen"
      >
        <mat-icon class="white-medium-icon">language</mat-icon>
      </button>
      <mat-menu xPosition="before" #langSelect="matMenu" class="lang-dropdown">
        <button
          *ngFor="let lang of LANGUAGES"
          mat-menu-item
          class="d-inline-block"
          [disabled]="currentLang === lang.key"
          (click)="changeLanguage(lang.key)"
        >
          <div class="d-flex justify-content-between w-100">
            <span>{{ t(lang.value) }}</span>
            <span class="text-uppercase">{{ lang.key }}</span>
          </div>
        </button>
      </mat-menu>
      <span class="font-l text-uppercase font-weight-bold ml-2xs align-self-center">{{
        currentLang
      }}</span>
    </cx-header-quickbar>

  </cx-header>
</ng-container>
