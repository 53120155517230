export const ROUTES = {
  HOME: '',
  ERROR: {
    ERROR: 'error',
    NOT_FOUND: 'not-found',
    NOT_AUTHORIZED: 'not-authorized',
    SERVER_ERROR: 'server-error',
    SESSION_EXPIRED: 'session-expired'
  },
  REDIRECT_URI: 'auth/callback',
  LOGIN: 'login',
  SILENT_REFRESH: 'silent-refresh'
};
